// import './App.css';
import React, { useEffect, useState } from "react";
import './App.css';
import { connectWallet, getCurrentWalletConnected } from './utils/interact';

import { ethers, utils } from 'ethers';
function GetWalletBalance() {
  const [balance, setBalance] = useState();

  const getBalance = async () => {

    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(account);
    setBalance(balance);
  };
}



export function Minter(props) {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [show, setShow] = useState(false);
  const [tokenNumber, setTokenNumber] = useState(true);
  const [soldOut, setSoldOut] = useState(true);


  useEffect(() => {
    async function fn() {
      const { address, status } = await getCurrentWalletConnected();
      setWallet(address);
      setStatus(status);
      addWalletListener();
      var d = new Date();
      var release = new Date("2022-09-04T21:00:00Z");
      var end = new Date("2022-09-07T21:00:00Z");

      setShow(d > release && d < end);


    }
    fn();

  }, []);


  const connectWalletPressed = async () => { //TODO: implement
    const wResponse = await connectWallet();
    setStatus(wResponse.status);
    setWallet(wResponse.address);

  };

  async function mintNFT(num) {
    const contract = require("./BDPGoldenEmblem.json")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract("0xEf41dcf9f1aebcB1D5C2e0B97E1ce394197b6739", contract.abi, signer);

    const price = num * 0.08;
    const connection = nftContract.connect(signer);
    // const addr = connection.address;
    let tokens = await nftContract.tokensOfOwner(walletAddress);

    if (tokens.length + num > 2) {
      alert("There is a limit of two NFTs per account - come back later or buy one of our other NFTs!");
    } else {
      const result = await nftContract.mintNFTs(num, { value: utils.parseEther(price.toString()), gasLimit: 500000 });
      await result.wait();
    }



  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  async function getTokenAmt() {
    const contract = require("./BDPGoldenEmblem.json")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract("0xEf41dcf9f1aebcB1D5C2e0B97E1ce394197b6739", contract.abi, signer);

    const connection = nftContract.connect(signer);

    let tokensLeft = await nftContract.getAmount() - 11;
    let tokensLeftTxt = tokensLeft.toString();
    var element = document.getElementById("tokens");
    let tokens = await nftContract.tokensOfOwner(walletAddress);

    if (show) {
      element.innerHTML = tokensLeftTxt + "/50 NFTs sold, buy yours fast!";
      if (tokens.length >= 2) {
        setTokenNumber(false);
      }
      if (tokensLeft >= 50) {
        setSoldOut(false)
      }
    }

  }
  getTokenAmt();
  //the UI of our component
  return (
    <div>

      {/* {walletAddress.length > 0 ? <button>HIHIHIHIHI</button> } */}

      <center>
        <button className="black-button" id="walletButton" onClick={connectWalletPressed} style={{ "fontSize": "20px", "position": "relative", "padding": "12px", "backgroundColor": "white", "color": "#1E1E1E" }}>

          {

            walletAddress.length > 0 ? (
              "Connected to wallet: " +
              String(walletAddress).substring(0, 6) +
              "..." +
              String(walletAddress).substring(38)
            ) : (
              <h2 style={{ "color": "white", "fontSize": "25px", "padding": "8px", "backgroundColor": "#1E1E1E", "color": "white" }}>Connect Wallet</h2>
            )}
        </button>
      </center>
      {(walletAddress.length > 0 && show) ? (
        <div>
          {soldOut ? (
            <div>
              {tokenNumber ? (
                <form style={{ "position": "relative", "top": "6px" }}>
                  <center>
                    <p id="tokens" style={{ "color": "#CC3200", "fontSize": "30px", "marginBottom": "5px" }}></p>
                    <input id="num" style={{ "color": "black", "fontSize": "30px", "borderStyle": "solid", "borderColor": "black", "borderWidth": "1px", "borderRadius": "5px", "padding": "2px" }} type={"number"} required defaultValue={1} min={1} max={2} placeholder={"How many?"}></input><br></br>
                    <button className="coolbutton" style={{ "position": "relative", "top": "6px", "backgroundColor": "#1E1E1E", "color": "white", "padding": "1.2vh", "borderRadius": "15px" }} onClick={event => {
                      event.preventDefault();
                      var num = document.getElementById("num").value;
                      if (num > 2 || num < 1) {
                        alert("Invalid Number of NFTs");
                      }
                      else {
                        mintNFT(num);
                      }
                    }}>
                      Mint your NFT!
                    </button>
                  </center>
                </form>
              )
                : (<div><center><p style={{ "color": "#CC3200", "fontSize": "30px" }}>You already bought 2 NFTs!</p></center></div>)}</div>)
            : (<div><center><p style={{ "color": "#CC3200", "fontSize": "30px" }}>SOLD OUT!</p></center></div>)}
        </div>
      ) : (<div><center><p style={{ "color": "#CC3200", "fontSize": "30px" }}>NFTs coming soon - September 4th to September 7th!</p></center></div>)}
    </div>
  );

}