import logo from './logo.svg';
import './App.css';
import { NavBar } from './NavBar.js';
import { NavBarMembers } from './NavBarMembers.js';
import { SmallCard } from './SmallCard.js';
import { LargeCard } from './LargeCard.js';
import { GoalsCard } from './LargeCard.js';
import { StepsCard } from './LargeCard.js';
import { scrollSmoothToBottom } from './NavBar.js';
import { Footer } from './Footer.js'
import { connectWallet, getCurrentWalletConnected } from './utils/interact';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers, utils } from 'ethers';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const SmoothScrollBottom = (id) => {
  const element = document.getElementById(id);

  const thing = document.getElementById('aboutNFT');
  const navbar = document.querySelector('#moreinfo')
  var posTop = thing.getBoundingClientRect().top;

  var offsetPos = posTop + window.pageYOffset - navbar.clientHeight;

  window.scrollTo(0, offsetPos);
}

function App() {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");

  const navigate = useNavigate();


  useEffect(() => {
    async function fn() {
      const { address, status } = await getCurrentWalletConnected();
      setWallet(address);
      setStatus(status);
      addWalletListener();
    }
    fn();


  }, []);


  const connectWalletPressed = async () => { //TODO: implement
    const wResponse = await connectWallet();
    setStatus(wResponse.status);
    setWallet(wResponse.address);

  };

  const onMintPressed = async () => { //TODO: implement

  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          // setStatus("👆🏽 Write a message in the text-field above.");

        } else {
          alert("connect to metamask");
          setWallet("");
          // setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      // alert("connect to metamask");

      setStatus(
        // <div id="#connect" className="overlay">
        <div style={{ "zIndex": "10" }}>

          {/* <h1>BROOOOOoOOOOOOOOOOOOO</h1> */}
          <p style={{ "position": "absolute", "top": "10%" }}>
            {" "}
            {" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>

          <a className="close" href="#">&times;</a>
          xx

        </div>
        // </div>


      );

    }
  }

  return (
    <div className="App" id="AppId">
      <NavBarMembers />
      <Container>
        <Row style={{ "marginTop": "10vh" }}>
          <Col xs={8} md={6} style={{ "top": "5vh", }}>
            <center>

              <h1 style={{ "marginTop": "30vh" }}><b>Golden Emblem</b></h1>
              <br />
              <h1 style={{ "fontSize": "3em", "position": "relative", }}><i>Black Diamond Print</i></h1>
              <a href='#mint'><button id="moreinfo" onClick={event => { }} type="button" style={{ "fontSize": "28px", "color": "white", "position": "relative", "paddingLeft": "20px", "paddingRight": "20px", "paddingTop": "5px", "paddingBottom": "5px", "marginBottom": "10vh", "marginTop": "1vh", "backgroundColor": "#1E1E1E", "borderRadius": "20px" }} className="coolbutton">Mint NFT</button></a>
            </center>
          </Col>
          <Col xs={10} md={6}>
            <img src="nft.gif" width={"500vw"} style={{ "right": "5%", "marginTop": "5vh", "borderRadius": "20px", "border": "10px solid #CC3200" }}></img>
          </Col>
        </Row>
        <br></br>
        <center>
          <h1 id="aboutNFT" style={{ "color": "white", "top": "110%", "left": "28%", "fontSize": "50px", "marginTop": "5vh", "marginBottom": "2vh", }}>About the BDP Golden Emblem Collection</h1>
        </center>
        <Row>
          <Col md={4}>
            <SmallCard id="mintdatecard" title="Mint Date" text="September 4th, 2022 - September 7th" icon="clock.jpg" />
            <SmallCard title="NFT Symbol" text="BDP" icon="document.jpg" />
            <SmallCard title="Blockchain" text="Ethereum" icon="eth.jpg" />
            <SmallCard title="How much Ethereum do I need to transfer?" icon="transfer.png" text="Each Golden emblem will cost $120, (.08). This does not include blockchain gas fees. We recommend transferring an additional $10-$20 to ensure that your gas fees are met." />
            <SmallCard title="How many NFTs can I buy?" icon="buy.png" text="Each individual wallet is limited to purchasing a maximum of 2 BDP Golden Emblem tokens." />
            <SmallCard title="What do I do now that I'm a holder of the Golden Emblem NFT?" icon="key.png" text="You will immediately gain access to exclusive perks that can be found in the Members Only tab. " />
          </Col>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <SmallCard title="Mint Price" text="0.08 ETH" icon="money.jpg" />
              </Col>
              <Col md={6}>
                <SmallCard title="NFTs Minted" text="60 (10 Reserved, 50 For Sale)" icon="piggy.jpg" />
              </Col>
            </Row>
            <Row>
              <Container>
                <GoalsCard title="What are the steps in getting started?" icon="start.png" text="60" height="180px" />
              </Container>
              <Container id="about">
                <LargeCard title="Benefits" text="60" icon="benefit.jpg" height="180px" />
              </Container>
              <Container>
                <GoalsCard title="Goals" text="60" icon="target.png" height="180px" />
              </Container>

            </Row>
          </Col>
        </Row>

      </Container>
      <br></br>
      <br></br>
      <Footer />
    </div >

  );



}

export default App;