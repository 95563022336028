import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function SmallCard(props) {
    const ds = {
        "backgroundColor": "#FFA000",
        "borderRadius": "25px",
        "padding": "2vh",
        "marginBottom": "2vh",

    }

    return (
        <div style={ds}>
            <h1 style={{ "fontSize": "50px", }}>{props.title}</h1>
            <img src={props.icon} style={{ "height": "5vh", }}></img>
            <h3 style={{ "fontSize": "30px", "paddingTop": "2vh" }}>{props.text}</h3>

        </div>
    );
}

export function SmallerCard(props) {
    const ds = {
        "backgroundColor": "#FFA000",
        "borderRadius": "25px",
        "padding": "2vh",
        "marginBottom": "2vh",
    }
    const titleStyle = {
        "fontSize": "25px",
        "position": "relative",
    }

    return (
        <div style={ds}>
            <center>
                <h1 style={titleStyle}>{props.title}</h1>
            </center>
        </div>
    );
}


