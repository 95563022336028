import './App.css';

export function Footer(props) {
    var body = document.body,
        html = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
    const ds = { "flexDirection": "column", "zIndex": "300", "backgroundColor": "#CC3200", "width": "100%", "color": "black", "textAlign": "center", "fontSize": "30px", "marginTop": "auto" }


    return (
        <footer className="footer" style={ds}>
            <p>Copyright &#169; 2022 Black Diamond Print Inc.</p>
            <p style={{ "fontSize": "25px", "color": "gray" }}>Copyright &#169; 2022 DigiMithril</p>
        </footer>
    );
}