// contain wallet & smart contract interactions

export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      console.log("failed connection to wallet");
      return {
        address: "",
        status: (
          <span>
            <p>
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };


export const getCurrentWalletConnected = async () => {
  console.log("checking wallet");  
  if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          console.log("worked others");
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          console.log("helloerror");
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        console.log("ERROR1");
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      console.log("ERROR2");
      
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
};
export const getCurrentWalletConnected2 = async () => {
  console.log("checking wallet");  
  if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          console.log("worked async");
          console.log(addressArray[0]);
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          console.log("helloerror");
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        console.log("ERROR1");
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      console.log("ERROR2");
      
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
};

// Provider
// const alchemyProvider = new ethers.providers.AlchemyProvider(network="goerli", API_KEY);

// // Signer
// const signer = new ethers.Wallet(PRIVATE_KEY, alchemyProvider);

// // Contract
// const helloWorldContract = new ethers.Contract(CONTRACT_ADDRESS, contract.abi, signer);


// async function main(){

// }