
import { Minter } from './Minter.js';
import './App.styl';
import MediaQuery from "react-responsive";
import { slide as Menu } from 'react-burger-menu';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom'

// import { Nav, NavItem, NavLink } from 'reactstrap';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// import DropdownButton from 'react-bootstrap/DropdownButton';
import { useState, useEffect } from 'react';
import { connectWallet, getCurrentWalletConnected } from './utils/interact.js';
import { MembersOnly } from './MembersOnly.js';

export const scrollSmoothToBottom = (id) => {
    console.log("hi");
    const element = document.getElementById(id);

    const thing = document.getElementById('aboutNFT');
    const navbar = document.querySelector('#newnavcontainer')
    var posTop = thing.getBoundingClientRect().top;
    // console.log(navbar.clientHeight);
    console.log(window.pageYOffset);
    var offsetPos = posTop + window.pageYOffset - navbar.clientHeight;
    console.log('offsetPos: ' + offsetPos);
    // thing.scrollIntoView({"alignToTop": true});
    // console.log(window.innerHeight);
    window.scrollTo(0, offsetPos);
}

export const scrollSmoothToTop = (id) => {
    console.log("hi");
    const element = document.getElementById(id);

    const thing = document.getElementById('homebutton');
    const navbar = document.querySelector('#newnavcontainer')
    var posTop = thing.getBoundingClientRect().top;
    // console.log(navbar.clientHeight);
    console.log(window.pageYOffset);
    var offsetPos = posTop - window.pageYOffset + navbar.clientHeight;
    console.log('offsetPos: ' + offsetPos);
    // thing.scrollIntoView({"alignToTop": true});
    // console.log(window.innerHeight);
    window.scrollTo(0, offsetPos);
}

const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
};

export function NavBarMembers() {
    const [buttonText, setButtonText] = useState('Social Media &#8593;');

    useEffect(() => {
        const timer = setTimeout(() => {
            setButtonText({ buttonText });
        }, 1000);
        return () => clearTimeout(timer);
    }, [buttonText])
    const navigate = useNavigate()
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");


    const homeStyle = {
        fontSize: '20px',
        color: '#1E1E1E',
    };
    useEffect(() => {
        async function fn() {
            const { address, status } = await getCurrentWalletConnected();
            setWallet(address);
            setStatus(status);
            addWalletListener();
        }

        fn();

        // console.log("loogging status")
        // console.log(status);

    }, []);


    const connectWalletPressed = async () => { //TODO: implement
        const wResponse = await connectWallet();
        setStatus(wResponse.status);
        setWallet(wResponse.address);

    };

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    setStatus("👆🏽 Write a message in the text-field above.");
                } else {
                    setWallet("");
                    setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            console.log("INSTALLLLL");
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

    return (
        <nav className="navbar navbar-inverse" style={{ "backgroundColor": "#FFFB01", "zIndex": "10000", "width": "100%", "position": "fixed" }} id="newnavcontainer">
            <div className="container-fluid">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar" style={{ "top": "15px", "backgroundColor": "#1E1E1E" }}>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a onClick={event => { navigate('/'); scrollSmoothToTop('buttonscroll') }}>
                        <ul style={flexContainer}>
                            <li><img style={{ "paddingRight": "2vh" }} src="/logo.png" alt="logo" height={100.01} width={80}></img></li>
                            <li style={{ "lineHeight": "25px", "top": "4px", "position": "relative", "right": "6px" }}>
                                <li style={{ "color": "black", "fontSize": "20px" }}>Black</li>
                                <li style={{ "color": "black", "fontSize": "20px" }}>Diamond</li>
                                <li style={{ "color": "black", "fontSize": "20px" }}>Print Inc.</li>
                            </li>
                        </ul>
                    </a>
                </div>
                <div className="collapse navbar-collapse" id="myNavbar" style={{}}>

                    <ul className="nav navbar-nav navbar-right" style={{ "top": "10px", "position": "relative" }}>
                        <li><a id='homebutton' onClick={event => { navigate('/'); scrollSmoothToTop('buttonscroll') }} style={homeStyle} className="coolbutton">Home</a></li>
                        <li><a id="buttonscroll" onClick={() => {
                            navigate('/')
                            console.log("hello")
                            scrollSmoothToBottom('buttonscroll')
                        }} style={{ "fontSize": "20px", "color": "#1E1E1E", }} className="coolbutton">About NFT</a></li>
                        <li><a id="membersonly" href="/membersonly" style={{ "fontSize": "20px", "color": "black" }} className="coolbutton">Members Only</a></li>
                        <div id="mint" className="overlay">
                            <div className="popup">
                                <center>
                                    <p style={{ "color": "black", "fontSize": "5vh" }} >Mint NFT</p>
                                </center>
                                <Minter />
                                <a className="close" href="#">&times;</a>
                            </div>
                        </div>
                        <li><a className="btn-cssbuttons" style={{}}><span style={{ "fontSize": "19.5px", "bottom": "1px", "color": "#1E1E1E" }}>SOCIAL MEDIA</span>
                            <ul>
                                <li><a href="https://discord.gg/gjfYyaYBEN"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z" /></svg></a></li>
                                <li><a href="https://www.instagram.com/blackdiamondprint/" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" /></svg></a></li>
                                <li><a href="https://twitter.com/BDPNFT" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z" /></svg></a></li>
                            </ul></a></li>
                        <li style={{ "bottom": "2px" }}><a id="mintNFT" href="#mint" style={{ "fontSize": "20px", "color": "white", "backgroundColor": "#1E1E1E", "borderRadius": "20px" }} onClick={event => { }} className="coolbutton">MINT TOKEN</a></li>


                    </ul>
                </div>
            </div>
        </nav>



    );
}


