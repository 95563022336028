import React, { useState, useRef } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import styled from 'styled-components'
import { connectWallet, getCurrentWalletConnected, getCurrentWalletConnected2 } from './utils/interact';
import { ethers, utils } from 'ethers';

const Form = () => {
    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbwcacmTysLYzDqYnEsRSANIff382G3BKcLN6OyjqJhSZQ7fyPcTUq6KBC44yrrdb-a2/exec"
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [walletAddress, setWallet] = useState("");

    async function fn() {

        const x = await getCurrentWalletConnected2();

        const address = x['address'];

        setWallet(address);

    }
    fn()

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),

        }).then(res => {
            console.log("SUCCESSFULLY SUBMITTED")
            setLoading(false)
            setSubmitted(true)
        })
            .catch(err => console.log(err))
    }

    return (
        <FormStyle>
            <div className="container">
                {submitted ? <center><h1>Submitted Form!</h1></center> :
                    <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                        <input type="hidden" name="Wallet Address" value={walletAddress} />
                        <input type="hidden" name="Date" value={new Date()} />
                        <div className="input-style">
                            <label htmlFor='Name'>
                                Name
                            </label>
                            <input type="text" id="name" name="Name" placeholder='Your Name' />
                        </div>
                        <br />
                        <div className="input-style">
                            <label htmlFor='E-mail'>E-mail</label>
                            <input type="email" name="Email" placeholder='Your Email' />
                        </div>
                        <br />
                        <div className="input-style" style={{ "width": "200px" }}>
                            <label htmlFor='Sex'>Sex</label>
                            <input list="sex" name="Sex" placeholder='Sex' />
                        </div>
                        <datalist id='sex'>
                            <option value="Male" />
                            <option value="Female" />
                            <option value="Prefer not to say" />
                        </datalist>
                        <br />
                        <div className="input-style">
                            <label htmlFor='Shirt Size'>Shirt Size</label>
                            <input list="size" name="Shirt Size" placeholder='Shirt Size' />
                        </div>
                        <br />
                        <div className="input-style">
                            <label htmlFor='Hoodie Size'>Hoodie Size</label>
                            <input list="size" name="Hoodie Size" placeholder='Hoodie Size' />
                        </div>
                        <datalist id='size'>
                            <option value="Youth S" />
                            <option value="Youth M" />
                            <option value="Youth L" />
                            <option value="Adult S" />
                            <option value="Adult M" />
                            <option value="Adult L" />
                            <option value="Adult XL" />
                        </datalist>
                        <br />
                        <div className="input-style" style={{ "marginBottom": "10px" }}>
                            <label htmlFor='Street'>Address</label>
                            <input type="text" name="Street" placeholder='Street' />
                        </div>
                        <div className="input-style" style={{ "marginBottom": "10px" }}>
                            <input list="text" name="City" placeholder='City' />
                        </div>
                        <div className="input-style" style={{ "marginBottom": "10px", "width": "300px" }}>
                            <input list="text" name="State" placeholder='State' />
                        </div>
                        <div className="input-style" style={{ "marginBottom": "10px", "width": "300px" }}>
                            <input list="text" name="Country" placeholder='Country' />
                        </div>
                        <div className="input-style" style={{ "marginBottom": "10px", "width": "200px" }}>
                            <input list="number" name="Zip" placeholder='Zip' />
                        </div>

                        <div className="input-style">

                            <input type="submit" value={loading ? "Submitting..." : "Submit"} style={{ "backgroundColor": "#52154E", "fontWeight": "bold" }} />
                        </div>
                    </form>}
            </div>
        </FormStyle>
    )
}

export default Form

const FormStyle = styled.div`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    .input-style{
                                        padding - top: 0.8em;
                                    display: flex;
                                    color: black;
                                    flex-direction: column;
                                    gap: 0.8em;

                                    label{
                                        font - family: 'Poppins', sans-serif;
            }

                                    input{
                                        outline: none;
                                    border: none;
                                    padding: 0.8em;
                                    border-radius: 2em;
            }
                                    input[type=submit]{
                                        background - color: #52154E;
                                    color: #FFFFFFFF;
                                    font-weight: bold;


            }
        }

    }

                                    `