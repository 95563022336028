import './App.css';

export function LargeCard(props) {

    const ds = {
        "backgroundColor": "#DF5114",
        "borderRadius": "25px",
        "paddingTop": "2vh",
        "paddingBottom": "1vh",
        "marginBottom": "2vh",

    }

    return (
        <div style={ds}>
            <h1 style={{ "fontSize": "50px", "margin": "10px" }}>{props.title}</h1>
            <img src={props.icon} style={{ "right": "55%", "height": "5vh", "top": "20px", "margin": "10px" }}></img>
            <ul style={{ "left": "33px", "top": "22%", "fontSize": "30px", "margin": "10px" }}>
                <li>- The BDP Golden Emblem collection offers guaranteed token-gated whitelist access to all Black Diamond Print future partnerships and endeavors. </li>
                <li>- This allows holders the advantage of viewing a specific collection early while also receiving pre-approved minting access.</li>
                <li>- Token-gated minting access guarantees a BDP Golden Emblem holder the right to reserve their spot when minting an NFT with us.</li>
                <li>- The collection itself will unlock early token-gated access and privileges surrounding Black Diamond Print merchandise and memorabilia.</li>
                <li>- All BDP Golden Emblem holders will also receive token-gated access to different channels within our discord. </li>
                <li>- These channels will unlock giveaways and opportunities limited to the BDP Golden Emblem holders. </li>
            </ul>


        </div>
    );
}


export function GoalsCard(props) {

    const ds = {
        "backgroundColor": "#DF5114",
        "borderRadius": "25px",
        "paddingTop": "2vh",
        "paddingBottom": "1vh",
        "marginBottom": "2vh",

    }

    return (
        <div style={ds}>
            <h1 style={{ "fontSize": "50px", "margin": "10px" }}>{props.title}</h1>
            <img src={props.icon} style={{ "right": "55%", "height": "5vh", "top": "20px", "margin": "10px" }}></img>
            <ul style={{ "left": "33px", "top": "22%", "fontSize": "30px", "margin": "10px" }}>
                <li>- Our goal is to create unique “token-gated” opportunities surrounding our Black Diamond Print Golden Emblem NFT. </li>
                <li>- We aim to create exclusive opportunities and privileges surrounding the clients we obtain and the partnerships we work with. </li>
                <li>- These opportunities that we create will be generated and facilitated for BDP Golden Emblem Holders. </li>
                <li>- We seek to create lasting, memorable and ongoing opportunities for our holders. </li>
            </ul>


        </div>
    );
}
export function StepsCard(props) {

    const ds = {
        "backgroundColor": "#DF5114",
        "borderRadius": "25px",
        "paddingTop": "2vh",
        "paddingBottom": "1vh",
        "marginBottom": "2vh",

    }

    return (
        <div style={ds}>
            <h1 style={{ "fontSize": "50px", "margin": "10px" }}>{props.title}</h1>
            <img src={props.icon} style={{ "right": "55%", "height": "5vh", "top": "20px", "margin": "10px" }}></img>
            <ul style={{ "left": "33px", "top": "22%", "fontSize": "30px", "margin": "10px" }}>
                <li>- First download Metamask.</li>
                <li>- Next create an Opensea account.</li>
                <li>- Then transfer (ETH) from coinbase to Metamask.</li>
                <li>- Lastly connect your wallet to our site and you're ready to go!</li>
            </ul>


        </div>
    );
}