// import './App.css';
import React, { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected, getCurrentWalletConnected2 } from './utils/interact';
import { ethers, utils } from 'ethers';

import { Nav } from "react-bootstrap";
import { NavBarMembers } from './NavBarMembers.js';

import { Footer } from './Footer.js'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "./Form";
// require('dotenv').config();


function GetWalletBalance() {
  const [balance, setBalance] = useState();

  const getBalance = async () => {

    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(account);
    setBalance(balance);
  };
}



export function MembersOnly(props) {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [canUse, setUse] = useState(false);

  useEffect(() => {
    async function fn() {

      const x = await getCurrentWalletConnected2();

      const address = x['address'];
      const status = x['status'];

      setWallet(address);
      setStatus(status);

      addWalletListener();

      checkToken();
    }
    fn();
  }, []);

  const connectWalletPressed = async () => {
    const wResponse = await connectWallet();
    setStatus(wResponse.status);
    setWallet(wResponse.address);
  };

  async function checkToken() {
    const x = await getCurrentWalletConnected2();

    const address = x['address'];
    const status = x['status'];

    if (!window.ethereum) {

      setUse(false);
    }

    const contract = require("./BDPGoldenEmblem.json")

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();


    let nftContract;
    try {
      nftContract = new ethers.Contract("0xEf41dcf9f1aebcB1D5C2e0B97E1ce394197b6739", contract.abi, signer);
    } catch {
      setUse(false);
    }



    let tokens;
    try {
      tokens = await nftContract.tokensOfOwner(address);
      tokens.length >= 1 ? setUse(true) : setUse(false);
    } catch {
      console.log("failed token retrieval")
    }





  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  //the UI of our component 
  return (
    <div>
      {canUse ? <div>
        <NavBarMembers />
        <Container>
          <br></br>
          <center>
            <Row style={{ "marginTop": "11vh" }}>
              <h1 id="membersonlytitle" style={{ "color": "white", "position": "relative", "fontSize": "70px", "margin-bottom": "5%" }}>Members Only</h1>
            </Row>
          </center>
          <Row md={2}>
            <center>
              <h1 id="membersonlytitle" style={{ "color": "white", "position": "relative", "fontSize": "40px", "margin-bottom": "5%", "marginTop": "-35px" }}>Exclusive benefits of purchasing our BDP Golden Emblem NFT!</h1>
            </center>
            <Col md={6}>

              <img src="nft.gif" width={"500vw"} height={"500vh"} style={{ "position": "relative", "borderRadius": "20px", "border": "10px solid #CC3200", "margin-bottom": "2vh" }}></img>
            </Col>
            <Col md={6}>
              <Row md={5} style={{ "margin-bottom": "10px" }}>
                <Col style={{
                  "backgroundColor": "#FFA000",
                  "borderRadius": "25px",
                  "padding": "10px", "margin": "5px",
                }}>
                  <Form />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <br></br>
        <Footer top="120%" position="relative" />
      </div >
        : <><NavBarMembers /><div style={{ "padding": "20px" }}> <center><p style={{ "color": "black", "fontSize": "35px" }}><br />Make sure you are <i>connected to your wallet</i> under the "mint token" button using the MetaMask extension, then purchase the Golden Emblem NFT to gain access to this page!</p></center></div></>
      }
    </div >
  );

}

export default MembersOnly;